import React, { Component } from 'react';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    UncontrolledAlert,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Form, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import HTML from '../components/forms/fields/html';

import ErrorModal from '../components/errorModal';
import DeleteModal from '../components/deleteModal';

import { SortableContainer, SortableElement, sortableHandle, } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { v4 as uuidv4 } from 'uuid';
import garabage from '../assets/svg/garbage.svg'
import arrows from '../assets/svg/arrows.svg'

import moreIcon from '../assets/svg/more.svg';
import Print from '../assets/svg/print.svg'
import Link from '../assets/svg/link.svg'
import Checkbox from '../components/forms/fields/check';
import Image from '../components/forms/fields/image';

const API_ENDPOINT = 'localhost'


function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


const SortableItem = SortableElement(({ value, update }) => <li className={value.className}>{value.component}</li>);

const SortableList = SortableContainer(({ items, updateSubMenu }) => {
    return (
        <ul className="prepare-questionary-sort-list">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const SortableAnswer = SortableElement(({ value }) => <div className={value.className}>{value.component}</div>);

const SortableAnswers = SortableContainer(({ items }) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableAnswer className="bam" key={`item-${index}`} index={index} value={value} />
            ))}
        </div>
    );
});




const DragHandle = sortableHandle(({ children }) => children);


class ReferralBuilder extends Component {
    constructor(props) {
        super(props);


        this.state = {
            sectionFormIdx: null,
            answerForm: null,
            deleteElementModal: null,
            deleteOptionModal: null,
            linkingElement: null,
            sections: [],
            items: [],
            products: [],
            questionModal: false

        };
    }



    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }

    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminFetch");
    }



    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('adminFetch', (data) => {
            if (data.collection == 'products') {
                this.setState({
                    products: data.result
                })
            }
        })
        this.props.socketIOClient.on('fetchQuestionary', (data) => {
            this.setState({
                items: data.items
            })

        })


        this.props.socketIOClient.emit('adminFetch', {
            collection: 'products',
            query: {},
            sort: { _id: 1 },
            skip: 0
        });

        this.props.socketIOClient.emit('fetchQuestionary', {
        });


    }


    updateQuestionary = () => {
        this.props.socketIOClient.emit('updateQuestionary', {
            items: this.state.items
        })
    }

    conditionIndexOf = (conditions, conditionQuestion, conditionAnswer) => {
        if (!conditions) {
            return -1;
        }

        for (let i = 0; i < conditions.length; i++) {
            if (!conditionAnswer) {
                if (conditions[i].conditionQuestion == conditionQuestion) {
                    return i;
                }
            } else {
                if (conditions[i].conditionQuestion == conditionQuestion && conditions[i].conditionAnswer == conditionAnswer) {
                    return i;
                }
            }
        }

        return -1;
    }

    getAnswer = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].answers) {
                for (let j = 0; j < this.state.items[i].answers.length; j++) {
                    if (this.state.items[i].answers[j].id == id) {
                        return this.state.items[i].answers[j];
                    }
                }
            }
        }
    }


    getItem = (id) => {
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].id == id) {
                return this.state.items[i];
            }
        }
    }




    checkRelations = (itemId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }


        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, null);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId) {

                            downLinks.push({
                                conditionElement: {
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }


                if (items[i].id == itemId) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        upLinks.push({
                            conditionElement: {
                                id: items[i].conditions[j].conditionQuestion,
                                answerId: items[i].conditions[j].conditionAnswer,
                                name: this.getItem(items[i].conditions[j].conditionQuestion) && this.getItem(items[i].conditions[j].conditionQuestion).name,
                                answer: this.getAnswer(items[i].conditions[j].conditionAnswer) && this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                            },
                            element: {
                                id: item.id,
                                name: item.name,
                                itemIndex: i,
                                conditionIndex: j
                            }
                        })
                    }
                }
            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }

    checkAnswerRelations = (itemId, answerId) => {
        let items = this.state.items;

        let itemsDict = {};
        for (let i = 0; i < items.length; i++) {
            itemsDict[items[i].id] = items[i];
        }

        let item = this.getItem(itemId)
        if (!item) {
            return null;
        }


        let upLinks = [];
        let downLinks = [];

        for (let i = 0; i < items.length; i++) {
            if (items[i].conditions) {

                let idx = this.conditionIndexOf(items[i].conditions, itemId, answerId);
                if (idx != -1) {
                    for (let j = 0; j < items[i].conditions.length; j++) {
                        if (items[i].conditions[j].conditionQuestion == itemId && items[i].conditions[j].conditionAnswer == answerId) {

                            downLinks.push({
                                conditionElement: {
                                    id: items[i].conditions[j].conditionQuestion,
                                    answerId: items[i].conditions[j].conditionAnswer,
                                    name: itemsDict[items[i].conditions[j].conditionQuestion].name,
                                    answer: this.getAnswer(items[i].conditions[j].conditionAnswer).name,
                                },
                                element: {
                                    id: items[i].id,
                                    name: items[i].name,
                                    itemIndex: i,
                                    conditionIndex: j
                                }
                            });

                        }
                    }
                }

            }


        }


        if (!upLinks.length && !downLinks.length) {
            return null;
        } else {
            return [upLinks, downLinks];
        }


    }



    generateSections = () => {
        let sections = {};
        let items = [{ type: 0 }, ...this.state.items];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                    })
                }
            }
        }

        return Object.values(sections);
    }

    /**
    * Upload document file
    * @author   Milan Stanojevic
    */
    uploadDocument = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {
            this.setState({
                _documentUploading: true
            })

            let formData = new FormData();
            formData.append('file', input.files[0]);

            fetch(API_ENDPOINT + '/chat/upload/document', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                },
                body: formData
            }).then((res) => res.json()).then((img) => {
                this.setState({
                    document: img
                })
                this.setState({
                    _documentUploading: null
                })
            });
        }
    }

    /**
    * Upload image file
    * @author   Milan Stanojevic
    */
    uploadImage = (e) => {
        let input = e.target;
        if (input.files && input.files[0]) {
            this.setState({
                _imageUploading: true
            })

            let formData = new FormData();
            formData.append('file', input.files[0]);

            fetch(API_ENDPOINT + '/chat/upload/image', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,

                },
                body: formData
            }).then((res) => res.text()).then((img) => {
                this.setState({
                    image: img
                })
                this.setState({
                    _imageUploading: null
                })
            });
        }
    }

    render() {

        console.log(this.state.questionModal)

        return (
            <div className="content">

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            {this.state.message ?
                                <UncontrolledAlert color="success">
                                    {this.state.message.translate(this.props.lang)}
                                </UncontrolledAlert>
                                :
                                null
                            }
                        </Col>


                        <Col lg="12">
                            <div className="panel build-questionary-section">

                                <SortableList helperClass="test123"
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                        let items = this.state.items;

                                        let checkOldIndex = this.checkRelations(items[oldIndex].id);


                                        if (oldIndex > newIndex) {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[0].length) {
                                                for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                    arr.push(checkOldIndex[0][i].conditionElement.id)
                                                }


                                                let max = 0;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j > max) {
                                                            max = j;
                                                        }
                                                    }
                                                }

                                                if (newIndex > max) {
                                                    items = arrayMove(items, oldIndex, newIndex);
                                                    this.setState({
                                                        items: items
                                                    })
                                                } else {
                                                    this.setState({ messageModalError: true })
                                                }
                                            } else {
                                                items = arrayMove(items, oldIndex, newIndex);
                                                this.setState({
                                                    items: items
                                                })
                                            }

                                        }
                                        else {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[1].length) {
                                                for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                    arr.push(checkOldIndex[1][i].element.id)
                                                }
                                                let min = items.length - 1;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j < min) {
                                                            min = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex < min) {
                                                    items = arrayMove(items, oldIndex, newIndex);
                                                    this.setState({
                                                        items: items
                                                    })
                                                } else {
                                                    this.setState({ messageModalError: true })
                                                }
                                            } else {
                                                items = arrayMove(items, oldIndex, newIndex);
                                                this.setState({
                                                    items: items
                                                })
                                            }
                                        }
                                        document.body.style.cursor = 'default';


                                    }}
                                    onSortOver={({ index, oldIndex, newIndex }) => {


                                        let items = this.state.items;



                                        let checkOldIndex = this.checkRelations(items[index].id);

                                        if (index > newIndex) {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[0].length) {
                                                for (let i = 0; i < checkOldIndex[0].length; i++) {
                                                    arr.push(checkOldIndex[0][i].conditionElement.id)
                                                }
                                                let max = 0;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j > max) {
                                                            max = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex > max) {
                                                    this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                } else {
                                                    this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                }
                                            } else {
                                                this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                            }

                                        }
                                        else {
                                            let arr = [];
                                            if (checkOldIndex && checkOldIndex[1].length) {
                                                for (let i = 0; i < checkOldIndex[1].length; i++) {
                                                    arr.push(checkOldIndex[1][i].element.id)
                                                }
                                                let min = items.length - 1;
                                                for (let i = 0; i < arr.length; i++) {
                                                    for (let j = 0; j < items.length; j++) {
                                                        if (arr[i] == items[j].id && j < min) {
                                                            min = j;
                                                        }
                                                    }
                                                }
                                                if (newIndex < min) {
                                                    this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                                } else {
                                                    this.setState({ cursorNoDrop: true }, () => { document.body.style.cursor = 'no-drop'; })
                                                }
                                            } else {
                                                this.setState({ cursorNoDrop: false }, () => { document.body.style.cursor = 'default'; })
                                            }
                                        }


                                    }}
                                    useDragHandle items={this.state.items.map((item, idx) => {
                                        let cssStyle = {};
                                        if (item.fontSize) {
                                            cssStyle.fontSize = item.fontSize;
                                        }
                                        if (item.fontWeight) {
                                            cssStyle.fontWeight = item.fontWeight;
                                        }
                                        if (item.fontStyle) {
                                            cssStyle.fontStyle = item.fontStyle;
                                        }


                                        return {
                                            component: (
                                                <div>
                                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                                        <div className="drag-handle">
                                                            <DragHandle><Isvg src={arrows} /> </DragHandle>
                                                        </div>
                                                        <span >{idx + 1}.</span>
                                                    </div>

                                                    {
                                                        item.type == 1 || item.type == 2 || item.type == 3 ?
                                                            <FormGroup tag="fieldset">
                                                                <Label size="sm"><input style={cssStyle} type="text" value={item.name} onChange={(e) => {
                                                                    let items = this.state.items;
                                                                    items[idx].name = e.target.value;
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }} className="edit-field-name" />
                                                                    {item.type != 3 ?
                                                                        <div className="text-options">
                                                                            <button className={item.fontStyle == 'italic' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontStyle = items[idx].fontStyle == 'italic' ? null : 'italic'; this.setState({ items }) }}><i className="fa fa-italic" /></button>
                                                                            <button className={item.fontWeight == 'bold' ? 'active' : ''} onClick={() => { let items = this.state.items; items[idx].fontWeight = items[idx].fontWeight == 'bold' ? null : 'bold'; this.setState({ items }) }}><i className="fa fa-bold" /></button>

                                                                            <UncontrolledDropdown size="sm">
                                                                                <DropdownToggle caret>
                                                                                    <i className="fa fa-font" />
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 10; this.setState({ items }) }}>10px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 12; this.setState({ items }) }}>12px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 14; this.setState({ items }) }}>14px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 16; this.setState({ items }) }}>16px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 18; this.setState({ items }) }}>18px</DropdownItem>
                                                                                    <DropdownItem onClick={() => { let items = this.state.items; items[idx].fontSize = 20; this.setState({ items }) }}>20px</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }


                                                                </Label>
                                                                <SortableAnswers onSortEnd={({ oldIndex, newIndex }) => {
                                                                    let items = this.state.items;
                                                                    let answers = JSON.parse(JSON.stringify(items[idx].answers));
                                                                    //answers = arrayMove(answers, oldIndex, newIndex);
                                                                    let tmp = answers[newIndex];
                                                                    answers[newIndex] = answers[oldIndex];
                                                                    answers[oldIndex] = tmp;
                                                                    items[idx].answers = answers
                                                                    this.setState({
                                                                        items: items
                                                                    }, () => {
                                                                        setTimeout(() => {
                                                                            this.forceUpdate()
                                                                        }, 50)
                                                                    })
                                                                }} useDragHandle items={
                                                                    item.answers.map((answer, aidx) => {
                                                                        return {
                                                                            component: (
                                                                                <FormGroup check key={answer.id}>

                                                                                    <Label size="sm" check>

                                                                                        <Input disabled size="sm" name={item.id} type={item.type == 1 ? 'radio' : 'checkbox'} />
                                                                                        {' '}

                                                                                        {(this.state.linkingElement !== null && this.state.linkingElement !== idx && this.state.linkingElement > idx) ?
                                                                                            <button className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) || (this.state.linkingElement !== null && this.state.items[this.state.linkingElement].conditions && this.state.items[this.state.linkingElement].conditions.length && this.conditionIndexOf(this.state.items[this.state.linkingElement].conditions, item.id, answer.id) != -1) ? 'linking-element active' : 'linking-element'} onClick={() => {
                                                                                                let items = this.state.items;
                                                                                                if (!items[this.state.linkingElement].conditions) {
                                                                                                    items[this.state.linkingElement].conditions = [];
                                                                                                }

                                                                                                let check = -1;
                                                                                                for (let i = 0; i < items[this.state.linkingElement].conditions.length; i++) {
                                                                                                    if (items[this.state.linkingElement].conditions[i].conditionQuestion == item.id && items[this.state.linkingElement].conditions[i].conditionAnswer == answer.id) {
                                                                                                        check = i;
                                                                                                    }
                                                                                                }

                                                                                                if (check != -1) {
                                                                                                    items[this.state.linkingElement].conditions.splice(check, 1);
                                                                                                } else {
                                                                                                    items[this.state.linkingElement].conditions.push({
                                                                                                        conditionAnswer: answer.id,
                                                                                                        conditionQuestion: item.id
                                                                                                    })
                                                                                                }

                                                                                                /*items[this.state.linkingElement].conditionQuestion = item.id;
                                                                                                items[this.state.linkingElement].conditionAnswer = answer.id;
                                                                                                */
                                                                                                this.setState({
                                                                                                    items,
                                                                                                    // linkingElement: null
                                                                                                });

                                                                                            }}>{answer.name}</button>
                                                                                            :
                                                                                            <>
                                                                                                <input type="text" value={answer.name} placeholder={'Enter option name...'.translate(this.props.lang)} className={(this.state.showLink && this.conditionIndexOf(this.state.showLink, item.id, answer.id) != -1) ? 'edit-field-input edit-field-input-active' : "edit-field-input"} onChange={(e) => {
                                                                                                    let items = this.state.items;
                                                                                                    items[idx].answers[aidx].name = e.target.value;
                                                                                                    this.setState({
                                                                                                        items
                                                                                                    })
                                                                                                }} />


                                                                                            </>
                                                                                        }

                                                                                        <button onClick={() => {
                                                                                            this.setState({
                                                                                                answerModal: { question: idx, answer: aidx },
                                                                                            })
                                                                                        }}><i className="fa fa-cog" /></button>


                                                                                        <button onClick={() => {
                                                                                            this.setState({
                                                                                                deleteOptionModal: { idx, aidx },
                                                                                            })
                                                                                        }}><Isvg src={garabage} /></button>
                                                                                        <DragHandle>
                                                                                            <div><Isvg src={arrows} className="arrows" /></div> </DragHandle>

                                                                                    </Label>
                                                                                </FormGroup>
                                                                            )
                                                                        }

                                                                    })
                                                                } />

                                                                <div className="add-new-option-wrap">
                                                                    <button className="add-new-option" onClick={() => {
                                                                        let items = this.state.items;
                                                                        items[idx].answers.push({
                                                                            id: uuidv4(),
                                                                            name: '',
                                                                        });
                                                                        this.setState({
                                                                            items
                                                                        })
                                                                    }}> {'Add new option'.translate(this.props.lang)} </button>
                                                                    {item.type == 2 && item.answerPrefix ?
                                                                        <FormGroup>
                                                                            <Checkbox translate={this.props.translate} onChange={() => {
                                                                                let items = this.state.items;
                                                                                items[idx].userInput = !items[idx].userInput;
                                                                                this.setState({ items })
                                                                            }} checked={item.userInput ? true : false} label={'User can enter text answer'.translate(this.props.lang)} />
                                                                        </FormGroup>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>

                                                            </FormGroup>
                                                            :
                                                            null
                                                    }

                                                    <div>

                                                        <UncontrolledDropdown direction="left">
                                                            <DropdownToggle caret>
                                                                {'Type'.translate(this.props.lang)}
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;
                                                                    if (item.type != 1 && item.type != 2) {
                                                                        items[idx].name = 'Single option answer name'.translate(this.props.lang);
                                                                        items[idx].answers = [
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            },
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            }
                                                                        ]
                                                                    }
                                                                    items[idx].type = 1;
                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'Single option answer'.translate(this.props.lang)}</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;

                                                                    if (item.type != 1 && item.type != 2) {
                                                                        items[idx].name = 'Multiple options answer name'.translate(this.props.lang);
                                                                        items[idx].answers = [
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            },
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            }
                                                                        ]
                                                                    } else {
                                                                        if (items[idx].name == 'Single option answer name'.translate(this.props.lang)) {
                                                                            items[idx].name = 'Multiple options answer name'.translate(this.props.lang);
                                                                        }
                                                                    }

                                                                    items[idx].type = 2;

                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'Multiple options answer'.translate(this.props.lang)}</DropdownItem>

                                                                <DropdownItem onClick={() => {
                                                                    let items = this.state.items;

                                                                    if (item.type != 1 && item.type != 3) {
                                                                        items[idx].name = 'Number'.translate(this.props.lang);
                                                                        items[idx].answers = [
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            },
                                                                            {
                                                                                id: uuidv4(),
                                                                                name: ''
                                                                            }
                                                                        ]
                                                                    } else {
                                                                        if (items[idx].name == 'Single option answer name'.translate(this.props.lang)) {
                                                                            items[idx].name = 'Number'.translate(this.props.lang);
                                                                        }
                                                                    }

                                                                    items[idx].type = 3;

                                                                    this.setState({
                                                                        items
                                                                    })
                                                                }}>{'Number'.translate(this.props.lang)}</DropdownItem>

                                                            </DropdownMenu>

                                                        </UncontrolledDropdown>

                                                        <button onClick={() => this.setState({ questionModal: idx })}><i className="fa fa-cog" /></button>

                                                        <>

                                                            {idx != 0 && item.type != 3 ?
                                                                <button className={this.state.linkingElement == idx ? 'linking-enabled' : (item.conditions && item.conditions.length) ? 'item-linked' : ''} onMouseEnter={() => {
                                                                    if (item.conditions && item.conditions.length) {
                                                                        this.setState({
                                                                            showLink: item.conditions
                                                                        })
                                                                    }
                                                                }} onMouseLeave={() => {
                                                                    if (item.conditions && item.conditions.length) {
                                                                        this.setState({
                                                                            showLink: null
                                                                        })
                                                                    }
                                                                }} onClick={() => {
                                                                    this.setState({
                                                                        linkingElement: this.state.linkingElement !== idx ? idx : null,
                                                                        //showLink: this.state.linkingElement !== idx ? item.conditions  : null
                                                                    })
                                                                }}><Isvg src={Link} /></button>
                                                                :
                                                                null
                                                            }

                                                        </>

                                                        <button onClick={() => {
                                                            this.setState({
                                                                deleteElementModal: idx,
                                                            })
                                                        }}><Isvg src={garabage} /></button>




                                                    </div>


                                                </div>
                                            )
                                        }
                                    })}></SortableList>
                            </div>



                            <div className="panel">
                                <Button color="primary" size="sm" onClick={() => {
                                    let items = this.state.items;
                                    items.push({
                                        name: 'Single option answer name'.translate(this.props.lang),
                                        answers: [
                                            {
                                                id: uuidv4(),
                                                name: ''
                                            },
                                            {
                                                id: uuidv4(),
                                                name: ''
                                            }
                                        ],
                                        id: uuidv4(),
                                        type: 1,
                                    });
                                    this.setState({
                                        items
                                    })
                                }} style={{ padding: '10px 22px' }}>{'Add new element'.translate(this.props.lang)}</Button>
                                <Button size="sm" onClick={() => this.updateQuestionary()} style={{ marginLeft: 20, padding: '10px 22px' }} color="primary">{'Save'.translate(this.props.lang).translate(this.props.lang)}</Button>

                            </div>

                        </Col>


                    </Row>

                    {
                        this.state.messageModalError ?
                            <Modal isOpen={this.state.messageModalError} toggle={() => this.setState({ messageModalError: !this.state.runModal })}>
                                <ModalHeader toggle={() => this.setState({ messageModalError: !this.state.messageModalError })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ messageModalError: !this.state.messageModalError })}>&times;</button>}>
                                    {/* {'Error message'.translate(this.props.lang)} */}
                                    <h4>{'Warning'.translate(this.props.lang)}</h4>
                                </ModalHeader>

                                <ModalBody>
                                    <p>{'You cannot drop here this element!'.translate(this.props.lang)}</p>
                                    <Button color='primary' onClick={() => this.setState({ messageModalError: !this.state.messageModalError })}>{'Ok'.translate(this.props.lang)}</Button>

                                </ModalBody>


                            </Modal>
                            :
                            null
                    }

                </Container>

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteElementModal !== null}
                    toggle={() => this.setState({ deleteElementModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        items.splice(this.state.deleteElementModal, 1);
                        this.setState({
                            items,
                            deleteElementModal: null
                        })
                    }}
                >
                    {'Delete element'.translate(this.props.lang)} <strong>{this.state.items[this.state.deleteElementModal] && this.state.items[this.state.deleteElementModal].name}</strong>?
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteOptionModal !== null}
                    toggle={() => this.setState({ deleteOptionModal: null })}
                    handler={() => {
                        let items = this.state.items;
                        items[this.state.deleteOptionModal.idx].answers.splice(this.state.deleteOptionModal.aidx, 1);
                        this.setState({
                            items,
                            deleteOptionModal: null,
                        })
                    }}
                >
                    {'Delete option'.translate(this.props.lang)}?
                </DeleteModal>

                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }

                {this.state.answerModal ?
                    <Modal isOpen={this.state.answerModal} size="lg">
                        <ModalHeader toggle={() => this.setState({ answerModal: !this.state.answerModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ answerModal: !this.state.answerModal })}>&times;</button>}>
                            <h4>{'Configure answer'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col lg="6">


                                    <FormGroup>
                                        <Label>Diagnose</Label>
                                        <Input type="textarea" style={{ height: 530 }} value={this.state.items[this.state.answerModal.question].answers[this.state.answerModal.answer].diagnose} onChange={(e) => {
                                            let items = this.state.items;
                                            items[this.state.answerModal.question].answers[this.state.answerModal.answer].diagnose = e.target.value;
                                            this.setState({ items })
                                        }}></Input>
                                    </FormGroup>

                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label>Title</Label>
                                        <Input type="text" value={this.state.items[this.state.answerModal.question].answers[this.state.answerModal.answer].title} onChange={(e) => {
                                            let items = this.state.items;
                                            items[this.state.answerModal.question].answers[this.state.answerModal.answer].title = e.target.value;
                                            this.setState({ items })
                                        }}></Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Description</Label>
                                        <Input type="textarea" value={this.state.items[this.state.answerModal.question].answers[this.state.answerModal.answer].description} onChange={(e) => {
                                            let items = this.state.items;
                                            items[this.state.answerModal.question].answers[this.state.answerModal.answer].description = e.target.value;
                                            this.setState({ items })
                                        }}></Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Image label="Image" value={this.state.items[this.state.answerModal.question].answers[this.state.answerModal.answer].image} onChange={(image) => {
                                            let items = this.state.items;
                                            items[this.state.answerModal.question].answers[this.state.answerModal.answer].image = image;
                                            this.setState({ items })
                                        }}></Image>
                                    </FormGroup>

                                </Col>

                            </Row>
                            <FormGroup>
                                <Label>Products</Label>
                                <div className="checkbox-select">
                                    {
                                        this.state.products.map((item) => {
                                            return (
                                                <FormGroup size="sm">
                                                    <Checkbox label={item.Name && item.Name.de} translate={this.props.translate} value={this.state.items[this.state.answerModal.question].answers[this.state.answerModal.answer].products && this.state.items[this.state.answerModal.question].answers[this.state.answerModal.answer].products.indexOf(item._id) != -1} onChange={(val) => {
                                                        let items = this.state.items;
                                                        if (!items[this.state.answerModal.question].answers[this.state.answerModal.answer].products) {
                                                            items[this.state.answerModal.question].answers[this.state.answerModal.answer].products = [];
                                                        }

                                                        if (val) {
                                                            items[this.state.answerModal.question].answers[this.state.answerModal.answer].products.push(item._id);
                                                        } else {
                                                            items[this.state.answerModal.question].answers[this.state.answerModal.answer].products.splice(items[this.state.answerModal.question].answers[this.state.answerModal.answer].products.indexOf(item._id), 1);
                                                        }

                                                        this.setState({ items })

                                                    }} />
                                                </FormGroup>
                                            )
                                        })
                                    }
                                </div>
                            </FormGroup>

                        </ModalBody>
                    </Modal>
                    :
                    null
                }


                {this.state.questionModal !== false && this.state.items[this.state.questionModal] ?
                    <Modal isOpen={this.state.questionModal !== false} size="lg">
                        <ModalHeader toggle={() => this.setState({ questionModal: !this.state.questionModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ questionModal: !this.state.questionModal })}>&times;</button>}>
                            <h4>{'Configure question'.translate(this.props.lang)}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col lg="6">
                                    {this.state.items[this.state.questionModal].type != 3 ?
                                        <FormGroup>
                                            <Checkbox label='Show as circle selector' translate={this.props.translate} value={this.state.items[this.state.questionModal].circleSelector} onChange={(e) => {
                                                let items = this.state.items;
                                                items[this.state.questionModal].circleSelector = e;
                                                this.setState({ items })
                                            }}></Checkbox>
                                        </FormGroup>
                                        :
                                        null
                                    }

                                    <FormGroup>
                                        <Checkbox label='Print on referral' translate={this.props.translate} value={this.state.items[this.state.questionModal].print} onChange={(e) => {
                                            let items = this.state.items;
                                            items[this.state.questionModal].print = e;
                                            this.setState({ items })
                                        }}></Checkbox>
                                    </FormGroup>

                                    <FormGroup>
                                        <Checkbox label='Text align right' translate={this.props.translate} value={this.state.items[this.state.questionModal].alignRight} onChange={(e) => {
                                            let items = this.state.items;
                                            items[this.state.questionModal].alignRight = e;
                                            this.setState({ items })
                                        }}></Checkbox>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Print width</Label>
                                        <Input type="text" value={this.state.items[this.state.questionModal].width} onChange={(e) => {
                                            let items = this.state.items;
                                            items[this.state.questionModal].width = e.target.value;
                                            this.setState({ items })
                                        }}></Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Print title</Label>
                                        <Input type="text" value={this.state.items[this.state.questionModal].printTitle} onChange={(e) => {
                                            let items = this.state.items;
                                            items[this.state.questionModal].printTitle = e.target.value;
                                            this.setState({ items })
                                        }}></Input>
                                    </FormGroup>


                                </Col>
                                <Col lg="6">
                                    {this.state.items[this.state.questionModal].type != 3 ?
                                        <FormGroup>
                                            <Image label="Image" value={this.state.items[this.state.questionModal].image} onChange={(image) => {
                                                let items = this.state.items;
                                                items[this.state.questionModal].image = image;
                                                this.setState({ items })
                                            }}></Image>
                                        </FormGroup>
                                        :
                                        null
                                    }

                                 


                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                    :
                    null
                }


            </div >
        );
    }
}

export default Page(ReferralBuilder);