
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';


import Dropzone from 'react-dropzone';
import GridLayout from 'react-grid-layout';

const API_ENDPOINT = 'https://rossen-shop.ch:61003';

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}


class FileMultiple extends Component {
    constructor(props) {
        super(props);
        this.dropzone = React.createRef()
        this.removeImage = this.removeImage.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.state = {
            files: [],
            imagesLayout: [],
            _uploading: [],
        };
    }


    componentDidMount() {
        let files = [];
        let imagesLayout = [];
        for (let i = 0; i < this.props.value.length; i++) {
            files.push(this.props.value[i]);
            imagesLayout.push(i);
        }

        this.setState({
            files: files,
            imagesLayout: imagesLayout,
        });

    }



    componentDidUpdate(prevProps, prevState) {

        if ((!this.state.files.length && this.props.value.length) && !(prevState.files.length && !this.state.files.length)) {
            let files = [];
            let imagesLayout = [];
            for (let i = 0; i < this.props.value.length; i++) {
                files.push(this.props.value[i]);
                imagesLayout.push(i);
            }

            this.setState({
                files: files,
                imagesLayout: imagesLayout,
            });

        }



        if (prevState.files.length && !this.state.files.length) {
            console.log("TTTTTTTTTTTT");
            let files = [];
            for (let i = 0; i < this.state.imagesLayout.length; i++) {
                files.push(this.state.files[this.state.imagesLayout[i]]);
            }

            this.props.onChange(files);

        }

        if (prevProps.lang != this.props.lang && this.props.lang){
            let files = [];
            let imagesLayout = [];
            for (let i = 0; i < this.props.value.length; i++) {
                files.push(this.props.value[i]);
                imagesLayout.push(i);
            }

            this.setState({
                files: files,
                imagesLayout: imagesLayout,
            });

        }

    }

    removeImage(idx) {

        //this.props.socketIOClient.emit("adminDeleteFile", { url: this.state.files[idx] });


        let files = this.state.files.slice(0, idx).concat(this.state.files.slice(idx + 1, this.state.files.length))
        let imagesLayout = this.state.imagesLayout.slice(0, idx).concat(this.state.imagesLayout.slice(idx + 1, this.state.imagesLayout.length))
        let _uploading = this.state._uploading.slice(0, idx).concat(this.state._uploading.slice(idx + 1, this.state._uploading.length))

        this.setState({
            files: files,
            imagesLayout: imagesLayout,
            _uploading: _uploading
        });
    }



    async onDrop(imageFiles) {

        let imagesLayout = [];
        let images = [];
        let _uploading = [];
        let len = this.state.files.length;
        for (let i = 0; i < imageFiles.length; i++) {
            
                let formData = new FormData();
                formData.append('file', imageFiles[i]);
    
                fetch(API_ENDPOINT + '/data/upload-image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                    },
                    body: formData
                }).then(parseText).then(({ result, status }) => {
                    let files = this.state.files;
                    let _uploading = this.state._uploading;
                    files[parseInt(len + i)] = 'https://rossen-shop.ch' + result;
                    _uploading[parseInt(len + i)] = null;
    
                    this.setState({
                        _uploading: _uploading,
                        files: files
                    }, () => {
                        this.props.onChange(this.state.files);
                    })
    
                })


            images.push(null);
            imagesLayout.push(i);
            _uploading.push(true);
        }

        this.setState({
            files: this.state.files.concat(images),
            imagesLayout: this.state.imagesLayout.concat(imagesLayout),
            _uploading: this.state._uploading.concat(_uploading)
        }, () => {
            let files = [];
            for (let i = 0; i < this.state.imagesLayout.length; i++) {
                files.push(this.state.files[this.state.imagesLayout[i]]);
            }

            this.props.onChange(files);
        });




    }



    onLayoutChange(layout) {
        //console.log(layout);
        let arr = [];
        for (let i = 0; i < layout.length; i++) {
            arr.push({ idx: layout[i].i, position: layout[i].y * 3 + layout[i].x });
        }

        arr.sort(function (a, b) { return (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0); });
        let imagesLayout = [];
        for (let i = 0; i < arr.length; i++) {
            imagesLayout.push(parseInt(arr[i].idx));
        }

        console.log(imagesLayout);

        this.setState({
            imagesLayout: imagesLayout
        }, () => {
            let files = [];
            for (let i = 0; i < this.state.imagesLayout.length; i++) {
                files.push(this.state.files[this.state.imagesLayout[i]]);
            }

            this.props.onChange(files);


        });

    }


    render() {
        console.log(this.state.files)
        let images = [];

        if (this.state.files) {
            let x = 0;
            let y = 0;
            for (let i = 0; i < this.state.files.length; i++) {
                let layout = { i: i.toString(), x: x, y: y, w: 1, h: 1 };
                let item = {
                    content: (
                        <div className="image" key={i.toString()} data-grid={layout}>
                            <div className="image-wrap">

                                <img src={this.state.files[i]} />
                                <div className={'options'}>
                                    <i className="mdi mdi-delete" onClick={() => this.removeImage(i)}></i>
                                </div>
                                {this.state._uploading[i] &&
                                    <div className="spinner-container">
                                        <div className="spinner"></div>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                };
                images.push(item);
                x++;
                if (x >= 3) {
                    y++;
                    x = 0;
                }
            }



        }


        return (
            <div className="input-wrap">
                <label>{this.props.translate(this.props.label)}</label>
                <div className="file-drop" ref={(ref) => this.dropzone = ref}>

                    <Dropzone
                        onDrop={this.onDrop}
                        className='dropzone'
                        activeClassName='active-dropzone'
                        multiple={true}>

                        <button button type="button"><i className="mdi mdi-file-outline"></i></button>




                    </Dropzone>


                    <GridLayout
                        className="grid-layout"
                        onLayoutChange={this.onLayoutChange}
                        width={this.dropzone.offsetWidth}
                        rowHeight={146}
                        compactType={'horizontal'}
                        isResizable={false}
                        verticalCompact={true}
                        horizontalCompact={true}
                        useCSSTransforms={true}
                        cols={3}
                    >

                        {
                            images.map((image, idx) => {
                                return (
                                    image.content
                                );
                            })
                        }
                    </GridLayout>


                </div>





            </div>
        );
    }
}

export default FileMultiple;